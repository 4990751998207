import React from "react"

import SEO from "../components/Seo"
import PageWrapper from "../components/PageWrapper"
import ContactUs from "../features/ContactUs"

const FeaturesPage = ({ location }) => {
  return (
    <PageWrapper location={location} containMeNot>
      <SEO title="alvyApp - Contact Us" />
      <ContactUs />
    </PageWrapper>
  )
}

export default FeaturesPage
