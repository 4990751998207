import React from "react"

import { Heading1, Heading2, Text } from "../../components/common"
import PageHeaderImage from "../../components/PageHeaderImage"

import ContactUsHeaderImage from "./ContactUsHeaderImage"
import ContactImage from "./ContactImage"

const ContactUs = () => {
  return (
    <div>
      <div style={{ backgroundColor: "#d8f9f0" }}>
        <PageHeaderImage
          Image={ContactUsHeaderImage}
          title="Contact Us"
          headingClassName="mt-8 md:mt-24 ml-16 md:ml-32 lg:ml-40"
        />
      </div>
      <div>
        <div className="container mx-auto py-8 md:py-20 px-4">
          <div className="lg:flex">
            <div className="flex flex-col flex-1 py-2">
              <div className="px-1 py-4">
                <Heading2 className="text-2xl md: text-3xl py-2">
                  Contact and feedback
                </Heading2>
              </div>
              <div className="p-2">
                <Text>Hello there, we’d love to hear from you.</Text>
              </div>
              <div className="p-2">
                <Text>
                  Whether it’s to say hi, ask a question or to give some
                  feedback. We’re here for you.
                </Text>
              </div>
              <div className="p-2">
                <Text>
                  You’ll get straight through to one of the creators of alvyApp
                  and we’ll respond to your message on the same day.
                </Text>
              </div>
              <div className="p-2">
                <a
                  class="text-xl no-underline hover:underline"
                  href="mailto:apps@bitmoremedia.com"
                >
                  apps@bitmoremedia.com
                </a>
              </div>
            </div>
            <div className="flex flex-1 justify-center items-center py-6">
              <ContactImage />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
